@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@font-face {
  font-family: 'brandon-grotesque';
  font-weight: 300;
  font-display: swap;
  src: url('https://use.typekit.net/af/2f8f3d/0000000000000000000132dd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2f8f3d/0000000000000000000132dd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2f8f3d/0000000000000000000132dd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
}

@font-face {
  font-family: 'brandon-grotesque';
  font-weight: 400;
  font-display: swap;
  src: url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
}

@font-face {
  font-family: 'brandon-grotesque';
  font-weight: 500;
  font-display: swap;
  src: url('https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
}

@font-face {
  font-family: 'brandon-grotesque';
  font-weight: 700;
  font-display: swap;
  src: url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
}

@font-face {
  font-family: 'brandon-grotesque';
  font-weight: 900;
  font-display: swap;
  src: url('https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('woff'),
    url('https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3')
      format('opentype');
}
/* http://modularscale.com/scale/?*/

/* Reset styles */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
header,
picture {
  margin: 0;
  padding: 0;
  border: 0;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

/* fixes accessibility issues  on Safari when list-style none */
ul li::before {
  content: '\200B';
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  width: auto;
  min-width: 320px;
  scroll-behavior: smooth;
  overscroll-behavior: auto;
  background-color: #fff;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  font-size: 100%;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  /* -moz-osx-font-smoothing: grayscale; /* font-smooth - Non-standard: This feature is non-standard and is not on a standards track. Do not use it on production sites facing the Web: it will not work for every user. There may also be large incompatibilities between implementations and the behavior may change in the future.
  -webkit-font-smoothing: antialiased; */
  scroll-snap-type: both mandatory;
  overscroll-behavior: contain; /* prevents CSS Scroll Chaining */
}

#root {
  margin: 0 auto;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}

[tabindex='-1']:focus {
  outline: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #0b2237 !important;
}

canvas {
  margin: 0 auto;
}

@media only screen and (min-width: 1px) and (max-width: 321px) {
  form.formik-form {
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
    max-width: 280px;
  }
  .suggestion-item {
    max-width: 280px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 470px) {
  form.formik-form {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .suggestion-item {
    max-width: 360px;
  }
}

@media only screen and (min-width: 768px) {
  form.formik-form {
    margin: 0 auto;
    padding: 20px;
    height: initial;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 670px;
    display: flex;
    flex-flow: column nowrap;
  }
}

/* override autofill background color */
input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px white inset;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/*
  Prevents width from jumping when modal is open
  while preventing scrolling of page below
*/
body.ReactModal__Body--open {
  overflow-anchor: none;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
}

.ReactModal__Overlay--after-open {
  z-index: 10000;
}

.react-slidedown {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.embeddedServiceHelpButton .helpButton .uiButton {
  border-radius: 999rem;
  background-color: #006ec8;
  font-family: 'brandon grotesque', sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #006ec8;
}

.embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceHelpButton .helpButton .uiButton:hover,
.embeddedServiceHelpButton .helpButton .helpButtonEnabled.uiButton,
.embeddedServiceHelpButton .helpButton .helpButtonEnabled.uiButton:hover,
.embeddedServiceHelpButton
  .helpButton
  .helpButtonEnabled.uiButton:hover::before,
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before,
.embeddedServiceHelpButton
  .helpButton
  .helpButtonEnabled.uiButton:focus::before,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton
  .helpButtonEnabled.uiButton,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover::before,
.embeddedServiceSidebarMinimizedDefaultU.helpButton:focus,
.embeddedServiceSidebarMinimizedDefaultUI,
.embeddedServiceSidebarMinimizedDefaultUI .helpButton,
.embeddedServiceSidebarMinimizedDefaultUI
  .helpButton
  .helpButtonEnabled.uiButton,
.embeddedServiceSidebarMinimizedDefaultUI
  .helpButton
  .helpButtonEnabled.uiButton:hover,
.embeddedServiceSidebarMinimizedDefaultUI
  .helpButton
  .helpButtonEnabled.uiButton:hover::before,
.embeddedServiceSidebarMinimizedDefaultUI .helpButton:hover::before {
  bottom: 1em;
  right: 1em;
  min-width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #006ec8;
  box-shadow: none;
  border: #006ec8;
  border-radius: 999rem;
  outline: none;
  line-height: 1.5;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  padding: 0 20px;
}

span.message {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: 'brandon-grotesque', sans-serif;
  text-decoration: none;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
  min-width: 100%;
  text-decoration: none;
}

.embeddedServiceSidebarMinimizedDefaultUI .minimizedImage,
.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
  vertical-align: middle;
}

h2[embeddedService-chatHeader_chatHeader] {
  font-family: 'brandon-grotesque', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
}

.embeddedServiceHelpButton .embeddedServiceIcon {
  vertical-align: middle;
}

@media only screen and (max-width: 500px) {
  span.embeddedServiceIcon {
    margin: 0 auto 0 10px;
  }
}

.formContent.embeddedServiceSidebarForm > .backgroundImgColorMask {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.embeddedServiceSidebarForm .backgroundImg {
  margin: 0 auto;
}

.backgroundImg {
  background-color: #ffffff;
  width: 50%;
}
.embeddedServiceSidebarMinimizedDefaultUI.helpButton .content {
  min-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 auto 0 10px;
  padding: 0 10px;
}

.uiLabel-left.form-element__label.uiLabel > span {
  color: #414c5c;
  font-family: 'brandon-grotesque', sans-serif;
  font-weight: 500;
  text-align: left;
}
/* SF Chat Window Submit button styles */
button.embeddedServiceSidebarButton:not(:disabled):focus,
button.embeddedServiceSidebarButton:not(:disabled):hover {
  background: #ffffff;
  border: 2px solid #006ec8;
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 180px;
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}

/* SF Chat Window Submit button styles */
button.embeddedServiceSidebarButton:not(:disabled):focus > span,
button.embeddedServiceSidebarButton:not(:disabled):hover > span,
.embeddedServiceSidebarButton:hover .label .bBody,
.uiButton--default:hover .label .bBody,
.uiButton--default:focus .label .bBody {
  color: #006ec8;
  font-family: 'brandon-grotesque', sans-serif;
  letter-spacing: 1px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  text-decoration: none;
}

.helpButtonLabel,
.message,
.helpButtonLabel::before,
.message::before {
  text-align: center;
  line-height: 1.5;
  text-decoration: none;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'brandon-grotesque', sans-serif;
  font-weight: 500;
  font-size: 18px;
}

/* Submit button inside chat form styles */
.embeddedServiceSidebarButton,
.embeddedServiceSidebarButton:not(:disabled),
.embeddedServiceSidebarButton:not(:disabled):hover,
.embeddedServiceSidebarButton:not(:disabled):focus,
.submitButton {
  width: 140px;
  min-width: 140px;
  max-width: 140px;
  border-radius: 999rem;
  background-color: #006ec8;
  box-shadow: none;
  text-transform: uppercase;
  text-decoration: none;
  font-family: 'brandon-grotesque', sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.embeddedServiceSidebarButton:not(:disabled):hover::before,
.embeddedServiceSidebarButton:not(:disabled):focus::before {
  border: 1px solid #006ec8;
  color: #006ec8;
  background: #ffffff;
}

.embeddedServiceHelpButton .helpButton .uiButton:hover::before {
  border-radius: 999rem;
  box-shadow: none;
  text-decoration: none;
  font-family: 'brandon-grotesque', sans-serif;
}

.embeddedServiceSidebarFormField .has-error .slds-style-inputtext,
.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:focus,
.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:not(:focus) {
  border: 1px solid #006ec8;
}

@media only screen and (max-width: 500px) {
  .modalContainer.sidebarMinimized.layout-docked.embeddedServiceSidebar
    > button,
  .sidebarHeader
    .minimizedContainer
    .helpButton
    .embeddedServiceSidebarMinimizedDefaultUI,
  .embeddedServiceHelpButton > .helpButton > .helpButtonEnabled.uiButton,
  .embeddedServiceHelpButton > .helpButton > .helpButtonEnabled.uiButton:hover,
  .embeddedServiceHelpButton
    > .helpButton
    > .helpButtonEnabled.uiButton:hover::before,
  .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus,
  .embeddedServiceHelpButton .helpButton .helpButtonEnabled.uiButton:focus,
  .embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before,
  .embeddedServiceHelpButton
    > .helpButton
    > .helpButtonEnabled.uiButton:focus::before,
  .embeddedServiceHelpButton
    > .helpButton
    > .helpButtonEnabled.uiButton:focus::after,
  .embeddedServiceSidebarMinimizedDefaultUI.helpButton,
  .embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover,
  .embeddedServiceSidebarMinimizedDefaultUI.helpButton:hover::before,
  .embeddedServiceSidebarMinimizedDefaultUI.helpButton:focus {
    position: fixed;
    top: 0.3em;
    left: 0.4em;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    border-radius: 999rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border: none;
    outline: none;
  }

  #helpButtonSpan,
  #helpButtonSpan:hover,
  #helpButtonSpan:hover::before {
    display: none;
  }

  .embeddedServicesSidebarButton .label,
  .embeddedServicesSidebarButton .label::before {
    display: none;
  }

  .embeddedServiceSidebarButton,
  .embeddedServiceSidebarButton:not(:disabled):hover::before,
  .embeddedServiceSidebarButton:not(:disabled):focus::before {
    border: 1px solid #006ec8;
    color: #006ec8;
    background: #fff;
  }

  .embeddedServiceHelpButton .helpButton .uiButton {
    box-shadow: none;
    text-decoration: none;
    font-family: 'brandon-grotesque';
    font-size: 18px;
  }
  .messageContent:first-child,
  body
    > div.modalContainer.sidebarMinimized.layout-docked.embeddedServiceSidebar
    > button
    > div {
    flex: 2 1;
    margin: 0 auto;
  }

  .embeddedServiceSidebarMinimizedDefaultUI:not(.waiting)
    .content
    .messageContent
    .minimizedText,
  .embeddedServiceSidebarMinimizedDefaultUI
    .content
    .messageContent
    .minimizedText {
    display: none;
  }

  .embeddedServiceSidebarMinimizedDefaultUI:not(.waiting)
    .messageContent
    .embeddedServiceIcon,
  .embeddedServiceSidebarMinimizedDefaultUI
    .messageContent
    .embeddedServiceIcon,
  .helpButton .helpButtonEnabled.uiButton.no-hover .embeddedServiceIcon {
    margin: 0 auto;
  }
}

/* The following style elements inside the Sales Force chat window and form */
.embeddedServiceSidebarFormField .uiInputSelect > .select,
.slds-style-select,
.slds-style-select::before,
.slds-style-select:focus,
.slds-style-inputtext,
.slds-style-inputtext:focus,
.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField .slds-style-select,
.embeddedServiceSidebarForm .fieldList .uiInputText input,
.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField,
.embeddedServiceSidebarFormField .slds-style-inputtext,
.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:not(:focus),
.embeddedServiceSidebarFormField .slds-style-inputtext:focus,
.embeddedServicesSidebarFormField .slds-style-inputtext .input {
  font-family: 'Open Sans', sans-serif;
}

.submitButton .uiButton--default .uiButton .embeddedServiceSidebarButton,
.submitButton
  .uiButton--default
  .uiButton
  .embeddedServiceSidebarButton::before,
span .label .dBody {
  font-family: 'brandon-grotesque', sans-serif;
  font-weight: 500;
  line-height: 1.5;
}

.inputSelect.embeddedServiceSidebarFormField .uiInputSelect:after {
  top: calc(50% + 10px);
  vertical-align: middle;
}
.minimizeButton,
.closeButton {
  outline: none;
}

.embeddedServiceSidebarFormField .has-error .slds-style-inputtext,
.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:focus,
.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:not(:focus) {
  border-color: #006ec8 !important;
}

.embeddedServiceSidebarFormField .uiInputDefaultError .form-element__help {
  color: #006ec8;
}

.embeddedServiceSidebarForm .backgroundImg,
.embeddedServiceSidebarForm .backgroundImgColorMask {
  width: 300px;
  min-width: 300px;
}

.sliderSelector {
  width: 30px;
  height: 30px;
  margin: 0 50px 0 50px;
  object-fit: contain;
}

.datePicker {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.autocomplete-container {
  width: 100%;
  margin: 0 auto;
  max-width: 350px;
}

input.address-autocomplete-input {
  cursor: 'pointer';
}

.hidden {
  display: none;
}

button[embeddedService-chatHeader_chatHeader] {
  font-size: 9px !important;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

